export function getDataFromCell(o: unknown) {
	if (o?.result) {
		return o?.result;
	}

	if (o?.richText) {
		return o?.richText?.map((a) => a?.text)?.join('');
	}

	return o;
}
