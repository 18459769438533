import { titleHeaderConst } from './titleHeaderConst';

export function PrintModeView({
	printModeRes,
}: { printModeRes: { key: string; value: any[] }[][] }) {
	return (
		<table className="">
			<tbody>
				<tr>
					{titleHeaderConst.map((titleName) => {
						return <td key={titleName}>{titleName}</td>;
					})}
				</tr>
				{printModeRes?.map((obj) => {
					return obj?.map((item) => {
						return (
							<tr key={item.key}>
								{titleHeaderConst.map((tName) => {
									return <td key={item.key + tName}>{item[tName]}</td>;
								})}
							</tr>
						);
					});
				})}
			</tbody>
		</table>
	);
}
